import React, {useState} from "react";
import {
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    Grid, TextField, IconButton, FormControlLabel, Checkbox, Radio, Box, Typography, Tooltip
} from "@mui/material";
import {useSnackbar} from 'notistack';
import MyDialogTitle from "../DialogTitle";
import useClipboard from "../../hooks/useClipboard";
import InventoryIcon from '@mui/icons-material/Inventory';
import {useFormik} from "formik";
import {AirplaneTicket, CheckOutlined, Delete, Edit} from "@mui/icons-material";
import clipboardAPI from "../../services/clipboardAPI";

const ManagementDialog = ({text}) => {
    const {repertorization, list, addClipboard} = useClipboard();
    const [showDialog, setShowDialog] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [clipboards, setClipboards] = useState(repertorization?.clipboards);

    const handleClose = () => setShowDialog(false);

    const handleDialogOpen = () => {
        setShowDialog(true)
    }

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: {
            clipboards: clipboards?.map(clip => {
                clip.toDelete = false;
                return clip
            })
        },
        async onSubmit(values) {
            const oldClipboards = values.clipboards.filter(c => { return c.id !== -1 })
            const newClipboards = values.clipboards.filter(c => { return c.id === -1 })

            newClipboards.forEach(async c => {
                await addClipboard({
                    name: c.name,
                })                    
            })
            
            try {
                await clipboardAPI.modifyInfo({
                    clipboards: oldClipboards
                })
                enqueueSnackbar(`Clipboards updated`, {
                    variant: 'success'
                });
                handleClose()
                list({})
            } catch (e) {
                enqueueSnackbar(`Clipboards could not be updated `, {
                    variant: 'error'
                });
            }
        }
    });

    const handleDelete = (i) => {
        const newClipboards = [...formik.values.clipboards];
        newClipboards[i] = {
            ...newClipboards[i],
            toDelete: !newClipboards[i].toDelete,
        };
        formik.setFieldValue('clipboards', newClipboards);
    }

    const handleName = (e, i) => {
        formik.values.clipboards[i].name = e.target.value
        formik.setFieldValue('clipboards', formik.values.clipboards)
    }

    const handleDefault = (e, i) => {
        if (e.target.checked) {
            formik.values.clipboards.map((clip, index) => {
                clip.default = index === i
                return clip
            })
        }
        formik.setFieldValue('clipboards', formik.values.clipboards)
    }

    const handleAddClip = () => {
        setClipboards([
            ...clipboards,
            {
                id: -1,
                name: "clipboard"+(clipboards.length+1),
                number: -1,
                rubrics: [],
                toDelete: false,
                userId: -1,                
            }
        ]);
    }

    return (
        <>
            <Tooltip title={text} placement={'bottom'} arrow={true}>
                <IconButton
                    sx={{color: 'white'}}
                    onClick={handleDialogOpen}
                >
                    <Edit/>
                </IconButton>
            </Tooltip>

            {showDialog &&
                <Dialog
                    open={showDialog}
                    fullWidth
                    maxWidth={'md'}
                    keepMounted
                    onClose={handleClose}
                >
                    <form noValidate onSubmit={formik.handleSubmit} autoComplete='off'>
                        <MyDialogTitle handleOpen={handleClose}>
                            Manage clipboards. <br/>
                            {text}
                        </MyDialogTitle>
                        <DialogContent>
                            {
                                formik.values.clipboards.map((clip, i) =>
                                    <Grid container alignItems={'center'} mt={1} spacing={2}>
                                        <Grid item xs={3}>
                                            <Typography sx={{whiteSpace: 'nowrap'}}>
                                                Number of rubrics: {clip.rubrics.length}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                required
                                                fullWidth
                                                label={'Name'}
                                                value={clip.name}
                                                variant={'outlined'}
                                                disabled={clip.toDelete}
                                                onChange={(e) => handleName(e, i)}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        disabled={clip.toDelete}
                                                        onChange={(e) => handleDefault(e, i)}
                                                        checked={clip.default || false}
                                                    />
                                                }
                                                label={'Default'}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            {
                                                clipboards.length > 1 &&
                                                <Box display={'flex'} justifyContent={'flex-end'}>
                                                    <IconButton onClick={() => handleDelete(i)}>
                                                        {clip.toDelete ? <CheckOutlined/> : <Delete/>}
                                                    </IconButton>
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            }

                        </DialogContent>
                        <DialogActions>
                        <Button
                                color='primary'
                                onClick={handleAddClip}
                                variant={"contained"}
                            >
                                Add
                            </Button>
                            <Button
                                color='primary'
                                type={'submit'}
                                variant={"contained"}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>

                </Dialog>}
        </>
    )
}

export default ManagementDialog;
